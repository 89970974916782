import React from "react";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import { Grid } from "@mui/material";
import Login from "../common/Login";

const MobileNav = ({ logo }) => {
    const isHomePage = useMatch("/");
    const path = useLocation();

  return (
    <nav className="mobile-header home-header" style={ isHomePage ? {background:"transparent"} : {background:"var(--primary-color--)"}}>

      <Grid container className="mobileNav" style={{alignItems: "center", paddingTop:"0px"}}>
        <Grid item md={8} className="mobileNavGrid">
          <div className="logo">
            <NavLink to="/">
              <div className="header-logo">
                <img
                src={logo}
                alt="logo"
                style={{maxHeight:"80px"}}
                />
              </div>
            </NavLink>
          </div>
        </Grid>
        <Grid item md={2} className="mobileNavGrid" sx={{ marginLeft: "auto", marginRight:"8px" }}>
          <Login />
        </Grid>
      </Grid>
      <div id="recaptcha-container"></div>
    </nav>
  );
};

export default MobileNav;
